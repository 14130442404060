import { EditorView } from "prosemirror-view";
import React from "react";
import { toast } from "sonner";
import getMarkRange from "@shared/editor/queries/getMarkRange";
import useCompletion from "~/hooks/useCompletion";
import useDictionary from "~/hooks/useDictionary";
import useEventListener from "~/hooks/useEventListener";
import AiPrompt from "./AiPrompt";
import { useEditor } from "./EditorContext";
import FloatingToolbar from "./FloatingToolbar";
import { SearchResult } from "./LinkEditor";

type Props = {
  isActive: boolean;
  onCreateLink?: (title: string) => Promise<string>;
  onSearchLink?: (term: string) => Promise<SearchResult[]>;
  onClickLink: (
    href: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
  onClose: () => void;
};

function isActive(view: EditorView, active: boolean): boolean {
  try {
    const { selection } = view.state;
    const paragraph = view.domAtPos(selection.from);
    return active && !!paragraph.node;
  } catch (err) {
    return false;
  }
}

export default function AiToolbar({
  onCreateLink,
  onSearchLink,
  onClickLink,
  onClose,
  ...rest
}: Props) {
  const dictionary = useDictionary();
  const { view } = useEditor();
  const menuRef = React.useRef<HTMLDivElement>(null);
  const { completion, complete, clearCompletion, isLoading, error } =
    useCompletion({
      api: "/prompts.ask",
      onError: (e) => {
        toast.error(e.message);
      },
    });

  useEventListener("mousedown", (event: Event) => {
    const tempPromptElement = Array.from(
      document.querySelectorAll("#ask-ai-prompt")
    ).pop();
    if (
      event.target instanceof HTMLElement &&
      ((menuRef.current && menuRef.current.contains(event.target)) ||
        tempPromptElement?.contains(event.target))
    ) {
      return;
    }
    onClose();
    handleRemoveMark();
  });

  const handleRemoveMark = (): void => {
    const { state, dispatch } = view;
    const {
      selection: { from: from, to: to },
    } = state;
    const markType = state.schema.marks.askAi;
    const range = getMarkRange(selection.$from, markType);
    if (!range || !range.mark) {
      return;
    }

    if (range.mark) {
      dispatch(state.tr.removeMark(from, to, range.mark));
    }

    view.focus();
  };

  const handleTransformWithAi = async ({
    prompt,
    option,
    command,
  }: {
    prompt: string;
    option: string;
    command?: string;
  }) => {
    await complete({ prompt, option, command });
  };

  const { selection } = view.state;
  const active = isActive(view, rest.isActive);

  return (
    <FloatingToolbar ref={menuRef} active={active} width={360}>
      <AiPrompt
        key={`${selection.from}-${selection.to}`}
        from={selection.from}
        to={selection.to}
        onTransform={handleTransformWithAi}
        onRemoveMark={handleRemoveMark}
        dictionary={dictionary}
        completion={completion}
        loading={isLoading}
        error={error}
        clearCompletion={clearCompletion}
        view={view}
      />
    </FloatingToolbar>
  );
}
