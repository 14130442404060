import { InsertRightIcon, TrashIcon } from "outline-icons";
import { Mark } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import * as React from "react";
import Markdown from "react-markdown";
import styled from "styled-components";
import normalizePastedMarkdown from "@shared/editor/lib/markdown/normalize";
import { s, hideScrollbars } from "@shared/styles";
import { useDocumentContext } from "~/components/DocumentContext";
import Flex from "~/components/Flex";
import { ResizingHeightContainer } from "~/components/ResizingHeightContainer";
import Scrollable from "~/components/Scrollable";
import { Dictionary } from "~/hooks/useDictionary";
import {
  ArrowDownWideNarrowSvg,
  CheckCheckSvg,
  ParaphraseIconSvg,
  RefreshCcwDotSvg,
  WrapTextSvg,
  ForwardSvg,
} from "~/icons";
import Input from "./Input";
import LinkSearchResult from "./LinkSearchResult";
import ToolbarButton from "./ToolbarButton";
import Tooltip from "./Tooltip";

export type SearchResult = {
  title: string;
  subtitle?: React.ReactNode;
  url: string;
};

type Props = {
  mark?: Mark;
  from: number;
  to: number;
  dictionary: Dictionary;
  onRemoveMark: ({ from, to }: { from: number; to: number }) => void;
  onTransform: ({
    prompt,
    option,
    command,
  }: {
    prompt: string;
    option: string;
    command?: string;
  }) => void;
  view: EditorView;
  completion: string | null;
  loading: boolean;
  error: string | null;
  clearCompletion: () => void;
};

const options = [
  {
    value: "paraphrase",
    label: "Paraphrase",
    explanation: "Paraphrase selection with AI",
    icon: ParaphraseIconSvg,
  },
  {
    value: "improve",
    label: "Improve writing",
    explanation: "Improve writing of selection with AI",
    icon: RefreshCcwDotSvg,
  },

  {
    value: "fix",
    label: "Fix grammar",
    explanation: "Fix grammar of selection with AI",
    icon: CheckCheckSvg,
  },
  {
    value: "shorter",
    label: "Make shorter",
    explanation: "Make selection shorter with AI",
    icon: ArrowDownWideNarrowSvg,
  },
  {
    value: "longer",
    label: "Make longer",
    explanation: "Make selection longer with AI",
    icon: WrapTextSvg,
  },
];

const optionsWhenContent = [
  {
    value: "replace",
    label: "Replace",
    explanation: "Replace selection with AI suggestion",
    icon: InsertRightIcon,
  },
  {
    value: "regenerate",
    label: "Regenerate",
    explanation: "Regenerate selection with AI",
    icon: RefreshCcwDotSvg,
  },
  {
    value: "discard",
    label: "Discard",
    explanation: "Discard AI suggestion",
    icon: TrashIcon,
  },
];

const AiPrompt = ({
  dictionary,
  completion,
  loading,
  clearCompletion,
  onRemoveMark,
  onTransform,
  view,
  to,
  from,
}: Props) => {
  const [value, setValue] = React.useState("");
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const resultsRef = React.useRef<HTMLDivElement>(null);
  const completionRef = React.useRef<HTMLDivElement>(null);
  const hasCompletion = !loading && completion && completion.length > 0;
  const hasContent = loading || (completion && completion.length > 0);
  const [internalHeight, setInternalHeight] = React.useState(0);
  const [selectedOption, setSelectedOption] = React.useState("");
  const { editor } = useDocumentContext();

  const selectedText = view.state.doc
    .cut(view.state.selection.from, view.state.selection.to)
    .textContent.trim();

  React.useEffect(
    () => () => {
      onRemoveMark({
        from,
        to,
      });
      clearCompletion();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    if (loading) {
      setInternalHeight(32);
    }

    if (hasCompletion) {
      setInternalHeight(completionRef.current?.clientHeight || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, completion, hasCompletion]);

  // TODO: Implement handleKeyDown
  // const handleKeyDown = (event: React.KeyboardEvent): void => {
  //   switch (event.key) {
  //     case "Enter": {
  //       event.preventDefault();
  //       return;
  //     }

  //     case "Escape": {
  //       event.preventDefault();
  //       onRemoveMark({
  //         from,
  //         to,
  //       });
  //       return;
  //     }

  //     case "ArrowUp": {
  //       if (event.shiftKey) {
  //         return;
  //       }
  //       event.preventDefault();
  //       event.stopPropagation();
  //       const prevIndex = selectedIndex - 1;

  //       setSelectedIndex(Math.max(-1, prevIndex));
  //       return;
  //     }

  //     case "ArrowDown":
  //     case "Tab": {
  //       if (event.shiftKey) {
  //         return;
  //       }

  //       event.preventDefault();
  //       event.stopPropagation();
  //       return;
  //     }
  //   }
  // };

  const handleFocusLink = (sIndex: number) => {
    setSelectedIndex(sIndex);
  };

  const handleTransform = (str: string, option: string, command?: string) => {
    setSelectedOption(option);
    if (option === "zap" && !command) {
      onTransform({ prompt: str, option, command: value });
    } else {
      onTransform({ prompt: str, option, command });
    }
  };

  const handleReplaceSelection = () => {
    if (!editor) {
      return;
    }
    const { state, dispatch } = view;
    const { tr } = state;
    const { selection } = state;
    const { pasteParser } = editor;

    if (!completion) {
      return;
    }

    const paste = pasteParser.parse(normalizePastedMarkdown(completion));
    let slice;
    if (paste) {
      slice = paste.slice(0);
    }

    if (!slice) {
      return;
    }
    dispatch(tr.setSelection(selection).replaceSelection(slice));
  };

  const handleSelectOption = (option: string, str: string = selectedText) => {
    switch (option) {
      case "discard":
        clearCompletion();
        setSelectedOption("");
        setValue("");
        break;
      case "replace":
        clearCompletion();
        handleReplaceSelection();
        break;
      case "regenerate":
        handleTransform(str, selectedOption);
        break;
      default:
        handleTransform(str, option);
        break;
    }
  };

  const handlePushCommand = () => {
    if (!value) {
      return;
    }

    handleTransform(selectedText, "zap", value);
  };

  const currentOptions = hasContent ? optionsWhenContent : options;

  return (
    <Wrapper id="ask-ai-prompt">
      {loading && (
        <CompletionWrapper internalHeight={32}>
          <CompletionContent>Loading...</CompletionContent>
        </CompletionWrapper>
      )}
      {hasCompletion && (
        <CompletionWrapper ref={completionRef} internalHeight={internalHeight}>
          <CompletionContent>
            <Markdown>{completion}</Markdown>
          </CompletionContent>
        </CompletionWrapper>
      )}
      <PromptContentWrapper hasContent={!!hasContent}>
        <Input
          value={value}
          placeholder={dictionary.askAnything}
          // onKeyDown={handleKeyDown}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />

        <Tooltip content={dictionary.continue}>
          <ToolbarButton onClick={handlePushCommand}>
            <ForwardSvg />
          </ToolbarButton>
        </Tooltip>
      </PromptContentWrapper>

      <PromptItems ref={resultsRef} role="menu">
        <ResizingHeightContainer>
          {currentOptions.map((option, index) => (
            <StyledPromptItem
              key={option.value}
              containerRef={resultsRef}
              title={option.label}
              subtitle={option.explanation}
              icon={<option.icon />}
              onPointerMove={() => handleFocusLink(index)}
              onClick={() => handleSelectOption(option.value)}
              selected={index === selectedIndex}
            />
          ))}
        </ResizingHeightContainer>
      </PromptItems>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  pointer-events: all;
  gap: 8px;
`;

const PromptItems = styled(Scrollable)`
  background: ${s("menuBackground")};
  clip-path: inset(0px -100px -100px -100px);
  box-shadow: ${s("menuShadow")};
  position: absolute;
  top: 100%;
  width: 100%;
  height: auto;
  left: 0;
  margin-top: -6px;
  border-radius: 0 0 4px 4px;
  max-height: 360px;
  padding: 8px 0;
  ${hideScrollbars()}

  @media (hover: none) and (pointer: coarse) {
    position: fixed;
    top: auto;
    bottom: 40px;
    border-radius: 0;
    max-height: 50vh;
    padding: 8px 8px 4px;
  }
`;

const StyledPromptItem = styled(LinkSearchResult)`
  gap: 4px;
  padding: 4px 8px;
`;

const PromptContentWrapper = styled(Flex)<{ hasContent: boolean }>`
  gap: 8px;
  width: calc(100% + 16px);
  margin: 0px -6px;
  padding: 8px;
  margin-top: -8px;
  z-index: 999;
  background: ${s("menuBackground")};

  ${(props) =>
    !props.hasContent &&
    `
    margin: 0;
    padding: 0;
  `}
`;

const CompletionWrapper = styled.div<{ internalHeight: number }>`
  background: ${s("menuBackground")};
  box-shadow: ${s("customShadow")};
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  margin-top: 6px;
  border-radius: 4px 4px 0 0;
  max-height: 360px;
  transform: translateY(-${(props) => props.internalHeight + 12}px);
  transition: transform 0.2s ease-in-out;
`;

const CompletionContent = styled.div`
  width: 100%;
  height: fit-content;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-size: 14px;
  line-height: 1.5;
  color: ${s("text")};
  padding: 8px;

  & > p:first-child {
    margin: 0;
  }
`;

export default AiPrompt;
