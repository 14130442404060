import { useState } from "react";
import { client } from "~/utils/ApiClient";
import useStores from "./useStores";

const useCompletion = ({
  api,
  onError,
}: {
  api: string;
  onError: (e: Error) => void;
}) => {
  const [completion, setCompletion] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { ui } = useStores();

  const clearCompletion = () => {
    setCompletion(null);
    setIsLoading(false);
    setError(null);
  };

  const complete = async ({
    prompt,
    option,
    command,
  }: {
    prompt: string;
    option: string;
    command?: string;
  }) => {
    setIsLoading(true);
    try {
      const response = await client.post(api, {
        prompt,
        option,
        command,
        collectionId: ui.activeCollectionId,
        documentId: ui.activeDocumentId,
      });
      setCompletion(response.data);
    } catch (e) {
      setError(e);
      onError(e);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  };

  return { completion, complete, clearCompletion, isLoading, error };
};

export default useCompletion;
