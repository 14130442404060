import {
  BoldIcon,
  CodeIcon,
  Heading1Icon,
  Heading2Icon,
  BlockQuoteIcon,
  LinkIcon,
  StrikethroughIcon,
  OrderedListIcon,
  BulletedListIcon,
  TodoListIcon,
  InputIcon,
  HighlightIcon,
  CommentIcon,
  ItalicIcon,
  OutdentIcon,
  IndentIcon,
  CopyIcon,
  Heading3Icon,
} from "outline-icons";
import { EditorState } from "prosemirror-state";
import { isInTable } from "prosemirror-tables";
import * as React from "react";
import isInCode from "@shared/editor/queries/isInCode";
import isInList from "@shared/editor/queries/isInList";
import isMarkActive from "@shared/editor/queries/isMarkActive";
import isNodeActive from "@shared/editor/queries/isNodeActive";
import { MenuItem } from "@shared/editor/types";
import { Dictionary } from "~/hooks/useDictionary";
import {
  FontSizeIconSvg,
  Heading4IconSvg,
  Heading5IconSvg,
  Heading6IconSvg,
  ParagraphIconSvg,
  MagicIconSvg,
} from "~/icons";

const getSelectedIcon = (state: any) => {
  const { schema } = state;
  switch (true) {
    case isNodeActive(schema.nodes.heading, { level: 1 })(state):
      return <Heading1Icon />;
    case isNodeActive(schema.nodes.heading, { level: 2 })(state):
      return <Heading2Icon />;
    case isNodeActive(schema.nodes.heading, { level: 3 })(state):
      return <Heading3Icon />;
    case isNodeActive(schema.nodes.heading, { level: 4 })(state):
      return <Heading4IconSvg />;
    case isNodeActive(schema.nodes.heading, { level: 5 })(state):
      return <Heading5IconSvg />;
    case isNodeActive(schema.nodes.heading, { level: 6 })(state):
      return <Heading6IconSvg />;
    case isNodeActive(schema.nodes.blockquote)(state):
      return <BlockQuoteIcon />;
    case isNodeActive(schema.nodes.checkbox_list)(state):
      return <TodoListIcon />;
    case isNodeActive(schema.nodes.bullet_list)(state):
      return <BulletedListIcon />;
    case isNodeActive(schema.nodes.ordered_list)(state):
      return <OrderedListIcon />;
    default:
      return <ParagraphIconSvg />;
  }
};

export default function formattingMenuItems(
  state: EditorState,
  isTemplate: boolean,
  isMobile: boolean,
  dictionary: Dictionary,
  toShowSpecialMenu: boolean
): MenuItem[] {
  const { schema } = state;
  const isTable = isInTable(state);
  const isList = isInList(state);
  const isCode = isInCode(state);
  const isCodeBlock = isInCode(state, { onlyBlock: true });
  const allowBlocks = !isTable && !isList;

  return [
    {
      name: "placeholder",
      tooltip: dictionary.placeholder,
      icon: <InputIcon />,
      active: isMarkActive(schema.marks.placeholder),
      visible: isTemplate,
    },
    {
      name: "askAi",
      tooltip: dictionary.askAi,
      icon: <MagicIconSvg />,
      label: dictionary.askAi,
      visible: !isCode,
    },
    {
      name: "separator",
      visible: !isCode,
    },
    {
      name: "blockType",
      tooltip: dictionary.blockType,
      icon: getSelectedIcon(state),
      visible: !isCode && allowBlocks && toShowSpecialMenu,
      children: [
        {
          name: "paragraph",
          tooltip: dictionary.paragraph,
          icon: <ParagraphIconSvg />,
          label: dictionary.paragraph,
        },
        {
          name: "heading",
          tooltip: dictionary.h1,
          icon: <Heading1Icon />,
          label: dictionary.h1,
          attrs: { level: 1 },
        },
        {
          name: "heading",
          tooltip: dictionary.h2,
          icon: <Heading2Icon />,
          label: dictionary.h2,
          attrs: { level: 2 },
        },
        {
          name: "heading",
          tooltip: dictionary.h3,
          icon: <Heading3Icon />,
          label: dictionary.h3,
          attrs: { level: 3 },
        },
        {
          name: "heading",
          tooltip: dictionary.h4,
          icon: <Heading4IconSvg />,
          label: dictionary.h4,
          attrs: { level: 4 },
        },
        {
          name: "heading",
          tooltip: dictionary.h5,
          icon: <Heading5IconSvg />,
          label: dictionary.h5,
          attrs: { level: 5 },
        },
        {
          name: "heading",
          tooltip: dictionary.h6,
          icon: <Heading6IconSvg />,
          label: dictionary.h6,
          attrs: { level: 6 },
        },
        {
          name: "blockquote",
          tooltip: dictionary.quote,
          icon: <BlockQuoteIcon />,
          label: dictionary.quote,
        },
        {
          name: "checkbox_list",
          tooltip: dictionary.checkboxList,
          icon: <TodoListIcon />,
          label: dictionary.checkboxList,
          visible: (allowBlocks || isList) && !isCode,
        },
        {
          name: "bullet_list",
          tooltip: dictionary.bulletList,
          icon: <BulletedListIcon />,
          label: dictionary.bulletList,
          visible: (allowBlocks || isList) && !isCode,
        },
        {
          name: "ordered_list",
          tooltip: dictionary.orderedList,
          icon: <OrderedListIcon />,
          label: dictionary.orderedList,
          visible: (allowBlocks || isList) && !isCode,
        },
      ],
    },
    {
      name: "separator",
      visible: !isCode && allowBlocks && toShowSpecialMenu,
    },
    {
      name: "font_size",
      tooltip: dictionary.fontSize,
      icon: <FontSizeIconSvg />,
      visible: !isCode && allowBlocks && toShowSpecialMenu,
      children: [
        {
          name: "font_size",
          tooltip: dictionary.small,
          label: "12px",
          attrs: { size: "12px" },
          shortcut: "Mod-Alt-1",
        },
        {
          name: "font_size",
          tooltip: dictionary.medium,
          label: "16px",
          attrs: { size: "16px" },
          shortcut: "Mod-Alt-2",
        },
        {
          name: "font_size",
          tooltip: dictionary.large,
          label: "20px",
          attrs: { size: "20px" },
          shortcut: "Mod-Alt-3",
        },
        {
          name: "font_size",
          tooltip: dictionary.huge,
          label: "24px",
          attrs: { size: "24px" },
          shortcut: "Mod-Alt-4",
        },
        {
          name: "font_size",
          tooltip: dictionary.shuge,
          label: "32px",
          attrs: { size: "32px" },
          shortcut: "Mod-Alt-5",
        },
        {
          name: "font_size",
          tooltip: dictionary.sshuge,
          label: "48px",
          attrs: { size: "48px" },
          shortcut: "Mod-Alt-6",
        },
      ],
    },
    {
      name: "separator",
      visible: !isCode,
    },
    {
      name: "strong",
      tooltip: dictionary.strong,
      icon: <BoldIcon />,
      active: isMarkActive(schema.marks.strong),
      visible: !isCode,
    },
    {
      name: "em",
      tooltip: dictionary.em,
      icon: <ItalicIcon />,
      active: isMarkActive(schema.marks.em),
      visible: !isCode,
    },
    {
      name: "strikethrough",
      tooltip: dictionary.strikethrough,
      icon: <StrikethroughIcon />,
      active: isMarkActive(schema.marks.strikethrough),
      visible: !isCode,
    },
    {
      name: "highlight",
      tooltip: dictionary.mark,
      icon: <HighlightIcon />,
      active: isMarkActive(schema.marks.highlight),
      visible: !isTemplate && !isCode,
    },
    {
      name: "code_inline",
      tooltip: dictionary.codeInline,
      icon: <CodeIcon />,
      active: isMarkActive(schema.marks.code_inline),
      visible: !isCodeBlock,
    },
    {
      name: "separator",
      visible: allowBlocks && !isCode,
    },
    {
      name: "heading",
      tooltip: dictionary.heading,
      icon: <Heading1Icon />,
      active: isNodeActive(schema.nodes.heading, { level: 1 }),
      attrs: { level: 1 },
      visible: allowBlocks && !isCode,
    },
    {
      name: "heading",
      tooltip: dictionary.subheading,
      icon: <Heading2Icon />,
      active: isNodeActive(schema.nodes.heading, { level: 2 }),
      attrs: { level: 2 },
      visible: allowBlocks && !isCode,
    },
    {
      name: "heading",
      tooltip: dictionary.subheading,
      icon: <Heading3Icon />,
      active: isNodeActive(schema.nodes.heading, { level: 3 }),
      attrs: { level: 3 },
      visible: allowBlocks && !isCode,
    },
    {
      name: "blockquote",
      tooltip: dictionary.quote,
      icon: <BlockQuoteIcon />,
      active: isNodeActive(schema.nodes.blockquote),
      attrs: { level: 2 },
      visible: allowBlocks && !isCode,
    },
    {
      name: "separator",
      visible: (allowBlocks || isList) && !isCode,
    },
    {
      name: "checkbox_list",
      tooltip: dictionary.checkboxList,
      icon: <TodoListIcon />,
      keywords: "checklist checkbox task",
      active: isNodeActive(schema.nodes.checkbox_list),
      visible: (allowBlocks || isList) && !isCode,
    },
    {
      name: "bullet_list",
      tooltip: dictionary.bulletList,
      icon: <BulletedListIcon />,
      active: isNodeActive(schema.nodes.bullet_list),
      visible: (allowBlocks || isList) && !isCode,
    },
    {
      name: "ordered_list",
      tooltip: dictionary.orderedList,
      icon: <OrderedListIcon />,
      active: isNodeActive(schema.nodes.ordered_list),
      visible: (allowBlocks || isList) && !isCode,
    },
    {
      name: "outdentList",
      tooltip: dictionary.outdent,
      icon: <OutdentIcon />,
      visible: isList && isMobile,
    },
    {
      name: "indentList",
      tooltip: dictionary.indent,
      icon: <IndentIcon />,
      visible: isList && isMobile,
    },
    {
      name: "separator",
      visible: !isCode,
    },
    {
      name: "link",
      tooltip: dictionary.createLink,
      icon: <LinkIcon />,
      active: isMarkActive(schema.marks.link),
      attrs: { href: "" },
      visible: !isCode,
    },
    {
      name: "comment",
      tooltip: dictionary.comment,
      icon: <CommentIcon />,
      label: isCodeBlock ? dictionary.comment : undefined,
      active: isMarkActive(schema.marks.comment),
    },
    {
      name: "separator",
      visible: isCode && !isCodeBlock,
    },
    {
      name: "copyToClipboard",
      icon: <CopyIcon />,
      tooltip: dictionary.copy,
      visible: isCode && !isCodeBlock,
    },
  ];
}
